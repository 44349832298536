<template>
  <div style="height: 100%">
    <v-container v-if="isLoading" fill-height>
      <v-card width="100%" dark class="text-center" flat color="transparent">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <div class="primary--text font-weight-bold py-4">로그아웃 중입니다</div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import setMeta from "@/utils/setMeta";
export default {
  created() {
    this.signOut();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    setMeta({
      title: "로그아웃",
      description: "Logout",
    });
  },
  methods: {
    async signOut() {
      this.isLoading = true;
      await this.$firebase.auth().signOut();
      window.localStorage.clear();
      this.$router.push("/login");
      this.isLoading = false;
    },
  },
};
</script>
